import styled from "styled-components";

export const StyledSpan = styled.span`
    
    color: ${({ theme }) => theme.primaryDark};
    display: inline;
    align-items: center;
    justify-content: center;
   
 
    

`;
